import facebookIcon from '../../images/social/facebook-icon.svg'
import instagramIcon from '../../images/social/instagram-icon.svg'
import youtubeIcon from '../../images/social/youtube.svg'

export const contacts = {
  email: 'hello@homeinteriorstudio.com',
  phone: '+91 8433884433',
  address: 'Mumbai, India',
}

export const socialIcons = [
  {
    name: 'Home Interior Studio Facebook',
    image: facebookIcon,
    link: 'https://www.facebook.com/studio843884433/',
    for: 'FACEBOOK',
  },
  {
    name: 'Home Interior Studio Instagram',
    image: instagramIcon,
    link: 'https://www.instagram.com/homeinteriorstudio/',
    for: 'INSTAGRAM',
  },
  {
    name: 'Home Interior Studio Twitter',
    image: youtubeIcon,
    link: 'https://www.youtube.com/channel/UCw5_K0t_fu1LHp7_I3dVSBw',
    for: 'YOUTUBE',
  },
]
