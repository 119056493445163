import React from 'react'
import { projects } from '../../../../utils'
import styles from './index.module.scss'

export default ({ positions, direction, translateValues, determineStyle }) => (
  <div className={styles.imgContainer}>
    {projects.map(project => {
      const showAnimation =
        (positions[project.id] > translateValues[0] && direction === 'prev') ||
        (positions[project.id] < translateValues.slice(-1).pop() &&
          direction === 'next') // "translateValues[0]" and "translateValues.slice(-1).pop()" gives 1st and last values repectively in the translate array.
      const imgStyle = determineStyle(project.id, showAnimation, 'Y')
      return (
        <img
          key={project.id}
          src={project.images[0].src}
          alt={project.heading}
          style={imgStyle}
        />
      )
    })}
  </div>
)
