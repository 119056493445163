import React, { useState } from 'react'
import { Masonry } from '../masonry'
import { portfolio } from '../../../utils'
import { Modal } from '../../modal'
import styles from './index.module.scss'

export default () => {
  const [modalIsOpen, openModal] = useState(false)
  const [image, setImage] = useState({})

  const closeModal = () => openModal(false)

  const selectedImage = image => {
    setImage(image)
    openModal(true)
  }

  return (
    <section className={styles.content}>
      <Masonry array={portfolio} square={true} viewImage={selectedImage} />
      <Modal
        modalIsOpen={modalIsOpen}
        closeModalHandler={closeModal}
        contentLabel="Fullscreen Image"
        styling={styles.modal}
      >
        <img src={image.src} alt={image.alt} className={styles.modal__image} />
        <div className={styles.modal__txt}>
          <span>{image.alt}</span>
        </div>
      </Modal>
    </section>
  )
}
