import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../header'
import Footer from '../footer'
import Aside from '../aside'
import Ad from '../ad'
import Content from './content'
import PageNotFound from '../page-not-found'
import { projects } from '../../utils'
import styles from './index.module.scss'

export default () => {
  const location = useLocation() // for accessing project's id.
  const [project, setProject] = useState({})

  useEffect(() => fetchProjectDetais())

  const fetchProjectDetais = () => {
    const id = location.state
    const allProjects = projects
    const selectedProject = allProjects.filter(project => project.id === id)
    setProject(selectedProject[0])
  }

  return (
    <>
      {project === undefined ||
      (project && Object.entries(project).length === 0) ? (
        <PageNotFound text="Select a project to view this page." />
      ) : (
        <>
          <Header />
          <Aside heading="PROJECT DETAILS" />
          <Ad />
          <div className={styles.details}>
            <Content project={project} />
          </div>
          <Footer full={true} />
        </>
      )}
    </>
  )
}
