import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'
import Home from '../components/home-pg'
import AboutUs from '../components/about-us-pg'
import Portfolio from '../components/portfolio-pg'
import ProjectDetails from '../components/project-details-pg'
import Services from '../components/services-pg'
import Contact from '../components/contact-us-pg'
import PageNotFound from '../components/page-not-found'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const NotFound = () => <PageNotFound text="This page does'nt exists!" />

const AppRouter = () => (
  <BrowserRouter>
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/" component={Home} exact={true} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/project-details" component={ProjectDetails} />
        <Route path="/services" component={Services} />
        <Route path="/contact" component={Contact} />
        <Route component={NotFound} />
      </Switch>
    </>
  </BrowserRouter>
)

export default AppRouter
