import React from 'react'
import { contacts } from '../../../utils'
import styles from './index.module.scss'

export default () => (
  <div className={styles.contactDetails}>
    <span>CALL</span>
    <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
    <span>WRITE</span>
    <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
  </div>
)
