import React from 'react'
import Header from '../header'
import Aside from '../aside'
import Ad from '../ad'
import Carousel from './carousel'
import RotateScreen from './rotate-screen'
import styles from './index.module.scss'

export default () => (
  <>
    <div className={styles.home}>
      <Header />
      <Aside heading="HOME INTERIOR STUDIO" />
      <Ad />
      <div className={styles.home__content}>
        <Carousel />
      </div>
    </div>
    <RotateScreen />
  </>
)
