import React from 'react'
import Header from '../header'
import Footer from '../footer'
import Aside from '../aside'
import Ad from '../ad'
import Banner from '../banner'
import Content from './content'
import { aboutUsBanner } from '../../utils'
import styles from './index.module.scss'

export default () => (
  <>
    <Header />
    <Aside heading="ABOUT OUR STUDIO" />
    <Ad />
    <div className={styles.about}>
      <Banner banner={aboutUsBanner} />
      <Content />
    </div>
    <Footer />
  </>
)
