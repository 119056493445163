import React from 'react'
import { socialIcons } from '../../utils'
import styles from './index.module.scss'

export default ({ horizontal }) => (
  <div
    className={
      horizontal
        ? `${styles.social} ${styles.social__horizontal}`
        : `${styles.social} ${styles.social__vertical}`
    }
  >
    {socialIcons.map((icon, index) => (
      <a key={index} target="_blank" rel="noopener noreferrer" href={icon.link}>
        <img src={icon.image} alt={icon.name} />
      </a>
    ))}
  </div>
)
