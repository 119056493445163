import React from 'react'
import RotateScreenIcon from '../../../images/rotate-screen-icon.svg'
import styles from './index.module.scss'

export default () => (
  <div className={styles.rotate}>
    <img src={RotateScreenIcon} alt="Rotate Screen to Portrait Mode" />
    <span>Please turn your device</span>
  </div>
)
