import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'

export default ({ movePrev, moveNext }) => (
  <div className={styles.nav}>
    <Link to="/about-us" className={styles.nav__expLink}>
      <span>START EXPLORE</span>
    </Link>
    <div>
      <button className={styles.nav__prevBtn} onClick={movePrev}>
        <span>PREV</span>
      </button>
      <button className={styles.nav__nextBtn} onClick={moveNext}>
        <span>NEXT</span>
      </button>
    </div>
  </div>
)
