import React from 'react'
import { Link } from 'react-router-dom'
import mailIcon from '../../images/mail-icon.svg'
import styles from './index.module.scss'

export default ({ white }) => (
  <Link
    to="/contact"
    className={
      white
        ? `${styles.getInTouch} ${styles.getInTouch_white}`
        : styles.getInTouch
    }
  >
    GET IN TOUCH
    <img src={mailIcon} alt="Get in touch" />
  </Link>
)
