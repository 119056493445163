import React from 'react'
import Header from '../header'
import Footer from '../footer'
import Aside from '../aside'
import Ad from '../ad'
import Banner from '../banner'
import Content from './content'
import { contactUsBanner } from '../../utils'
import styles from './index.module.scss'

export default () => (
  <>
    <Header />
    <Aside heading="CONTACT US" />
    <Ad />
    <div className={styles.contact}>
      <Banner banner={contactUsBanner} />
      <Content />
    </div>
    <Footer />
  </>
)
