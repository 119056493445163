import React from 'react'
import styles from './index.module.scss'

export default ({ banner }) => {
  const doc = new DOMParser().parseFromString(banner.heading, 'text/html') // for retrieving text with html DOM element.
  const startingText = doc.body.firstChild
  const brTextElement =
    startingText.nextSibling !== null ? startingText.nextSibling : undefined // br tag.
  const endingText =
    brTextElement && brTextElement.nextSibling !== null
      ? brTextElement.nextSibling
      : undefined
  return (
    <section
      className={styles.banner}
      style={{ '--banner-bg': `url(${banner.image})` }}
    >
      <div className={styles.banner__overlay}></div>
      <div className={styles.banner__content}>
        <h2>
          {startingText.textContent}
          {brTextElement && <br />}
          {endingText && endingText.textContent}
        </h2>
        <p>{banner.subTitle}</p>
      </div>
    </section>
  )
}
