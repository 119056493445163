import React from 'react'
import { useHistory } from 'react-router-dom'
import HomeInteriorStudio from '../../images/home-interior-studio.png'

export default () => {
  const history = useHistory()

  return (
    <img src={HomeInteriorStudio} alt="Home Interior Studio" onClick={() => history.push('/')} />
  )
}
