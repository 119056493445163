import React from 'react'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'

export default ({ text }) => (
  <>
    <p className={styles.text}>{text}</p>
    <Link to="/" className={styles.link}>
      Go home
    </Link>
  </>
)
