import bannerBg from '../../images/backgrounds/bg9.jpg'
import storyBg from '../../images/backgrounds/bg7.jpg'

export const banner = {
  image: bannerBg,
  heading: 'ABOUT OUR <br />STUDIO', // <br /> can be optional.
  subTitle: 'Creating innovative interior design solutions for tomorrow.',
}

export const story = {
  name: 'OUR COMPANY STORY',
  shortSummary: 'Exquisite interiors customized to your needs.',
  image: storyBg,
  highlight: 'Your Thought, Our Design',
  paragraphs: [
    'We are a Mumbai based company situated in Mira Road (E), specialized in providing interior services for <strong>Residential and Commercial</strong> spaces since 2008. In today’s time where TIME IS MONEY, we help in saving time by taking care of all the work so that you don’t have to hire different contractors for different work.',
    'One of our specialties is <strong>3D designing</strong>. We literally design your home on our software and show you exactly how your home will look like once we complete our work. We create an actual visual reality for you to understand and decide on how you would want your home to exactly look like.',
    'Our prime focus is to provide <strong>Client Satisfaction</strong> and give services at competitive rates with excellent quality.',
  ], // strong tags can be optional.
}

export const stats = {
  projects: 145,
  customers: 357,
}

export const team = {
  name: 'OUR AWESOME TEAM',
  shortSummary:
    'We have our own team of civilized labor to take care of the quality of work.',
  staff: [
    '2 Designers',
    '2 Site Supervisors',
    'Carpenter Team',
    '2 Electricians',
    '2 Mason',
    'False Ceiling Team',
    '1 Plumber',
    'CCTV Surveillance and Security Team',
    '2 Painters',
    'Glazier (Glass Work) Team',
    'Fabrication (Aluminium, MS and SS)',
    '5 Helpers',
  ],
}
