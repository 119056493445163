import React from 'react'
import ReactModal from 'react-modal'
import cancelIcon from '../../images/cancel-icon.svg'
import './index.scss' // This file contains overwritten styles for react-modal and is imported seperately because index.module.scss generates modified/random names of the original classnames written in it. Therefore importing seperately so that our classnames do not change.

export const Modal = props => (
  <ReactModal
    isOpen={
      !!props.modalIsOpen
    } /* "!!" indicates 'true boolean' eg: !!'string' results in true, !!undefined results in false */
    onRequestClose={props.closeModalHandler}
    ariaHideApp={false}
    contentLabel={
      props.contentLabel
    } /* used for users with accessibility settings ON */
    closeTimeoutMS={100}
    style={{
      overlay: {
        backgroundColor: 'rgba(45,45,50,0.75)', // $primary-color with opacity 0.75.
      },
    }}
    className={props.styling}
  >
    <img
      src={cancelIcon}
      alt="Cancel Icon"
      className="modal-close-btn"
      onClick={props.closeModalHandler}
    />

    {/* Content inside modal starts here */}
    {props.children}

    {/* Content inside modal ends here */}
  </ReactModal>
)
