import React from 'react'
import styles from './index.module.scss'

export default ({ name, image, list }) => (
  <div className={styles.container}>
    <img src={image} alt={name} />
    <ul className={styles.container__list}>
      {list.map((item, index) => {
        const doc = new DOMParser().parseFromString(item, 'text/html') // for retrieving text with html DOM element.
        const strongTextElement = doc.body.firstChild // strong tag.
        const middleText = strongTextElement.nextSibling
        const lastElement = middleText.nextSibling
        return (
          <li key={index}>
            <strong>{strongTextElement.textContent}</strong>
            {middleText.textContent}
            {lastElement.nodeName === 'A' ? (
              <a
                href={
                  lastElement.textContent.indexOf('@') > -1
                    ? `mailto:${lastElement.textContent}`
                    : `tel:${lastElement.textContent}`
                }
              >
                {lastElement.textContent}
              </a>
            ) : (
              <span>{lastElement.textContent}</span>
            )}
          </li>
        )
      })}
    </ul>
  </div>
)
