import React from 'react'
import Section from '../../section'
import SerialNoLarge from '../../serial-no-large'
import ImageAndList from '../../image-and-list'
import Form from '../form'
import { details, getInTouch } from '../../../utils/'
import styles from './index.module.scss'

export default () => (
  <div className={styles.content}>
    <Section>
      <div className={styles.content__contact}>
        <SerialNoLarge srNo={1} />
        <h3>{details.name}</h3>
        <p>{details.shortSummary}</p>
        <ImageAndList
          name={details.name}
          image={details.image}
          list={details.list}
        />
      </div>
      <div className={styles.content__getInTouch}>
        <SerialNoLarge srNo={2} />
        <h3>{getInTouch.name}</h3>
        <p>{getInTouch.shortSummary}</p>
        <Form />
      </div>
    </Section>
  </div>
)
