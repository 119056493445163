import React, { useState } from 'react'
import { projects } from '../../../utils'
import TextContainer from './txt-container'
import ImageContainer from './img-container'
import NavBtns from './nav-btns'
import styles from './index.module.scss'

let translateValues = [] // translate values w.r.t. no. of images.

function getTranslateValues() {
  let j = (projects.length - 1) * 100
  for (let i = -100; i < j; i += 100) {
    translateValues.push(i)
  }
  return translateValues // returns [-100, 0, 100, 200, ...].
}

export default () => {
  const [direction, setDirection] = useState('')
  const [positions, setPosistions] = useState(getTranslateValues())

  const movePrev = () => {
    let positionsNew = positions.slice()
    positionsNew.push(positionsNew.shift())
    setDirection('prev')
    setPosistions(positionsNew)
  }

  const moveNext = () => {
    let positionsNew = positions.slice()
    positionsNew.unshift(positionsNew.pop())
    setDirection('next')
    setPosistions(positionsNew)
  }

  const determineStyle = (index, showAnimation, axis) => {
    const num = positions[index]
    if (showAnimation) {
      return {
        transform: `translate3d(${axis === 'X' ? `${num}%` : '0px'},${
          axis === 'Y' ? `${num}%` : '0px'
        },0px)`,
        WebkitTransition: 'all 0.5s ease-in-out',
        MozTransition: 'all 0.5s ease-in-out',
        msTransition: 'all 0.5s ease-in-out',
        OTransition: 'all 0.5s ease-in-out',
      }
    } else {
      return {
        transform: `translate3d(${axis === 'X' ? `${num}%` : '0px'},${
          axis === 'Y' ? `${num}%` : '0px'
        },0px)`,
      }
    }
  }

  return (
    <div className={styles.carousel}>
      <div className={styles.carousel__background}></div>
      <div className={styles.carousel__overlay}></div>
      <TextContainer
        positions={positions}
        direction={direction}
        translateValues={translateValues}
        determineStyle={determineStyle}
      />
      <ImageContainer
        positions={positions}
        direction={direction}
        translateValues={translateValues}
        determineStyle={determineStyle}
      />
      <NavBtns movePrev={movePrev} moveNext={moveNext} />
    </div>
  )
}
