import React, { useState, useEffect } from 'react'
// import { useHistory } from 'react-router-dom'
import ContactDetails from './contact-details'
import Share from './share-btn'
import SideNav from './side-nav'
// import logo from '../../images/logo.svg'
import Logo from '../logo'
import menuIcon from '../../images/menu-icon.svg'
import cancelIcon from '../../images/cancel-icon.svg'
import styles from './index.module.scss'

export default () => {
  const [sideDrawerOpen, toggleSideDrawer] = useState(false)

  const backdropClickHandler = () => toggleSideDrawer(false)

  return (
    <div id="header" className={styles.headerContainer}>
      <Header
        isMenuOpen={sideDrawerOpen}
        sideDrawerClickHandler={() => toggleSideDrawer(!sideDrawerOpen)}
      />
      <SideDrawer show={sideDrawerOpen} />
      {sideDrawerOpen ? (
        <div className={styles.backdrop} onClick={backdropClickHandler}></div>
      ) : null}
    </div>
  )
}

const Header = ({ isMenuOpen, sideDrawerClickHandler }) => {
  const [desktopView, setView] = useState(false)
  // const history = useHistory()

  useEffect(() => {
    if (window.innerWidth >= 1200) {
      setView(true)
    } else {
      setView(false)
    }
  }, [desktopView])

  return (
    <header className={styles.header}>
      <div className={styles.header__companyLogo}>
        {/* <img
          src={logo}
          alt="Home Interior Studio"
          onClick={() => history.push('/')}
        /> */}
        <Logo />
      </div>
      {desktopView ? <ContactDetails /> : <Share />}
      <img
        src={isMenuOpen ? cancelIcon : menuIcon}
        alt="Menu"
        className={
          isMenuOpen ? styles.header__closeBtn : styles.header__menuIcon
        }
        onClick={sideDrawerClickHandler}
      />
    </header>
  )
}

const SideDrawer = ({ show }) => {
  return (
    <div
      className={
        show
          ? `${styles.sideDrawer} ${styles.sideDrawer_open}`
          : styles.sideDrawer
      }
    >
      {/* Content shown on Side Drawer starts  */}

      <SideNav />

      {/* Content shown on Side Drawer ends  */}
    </div>
  )
}
