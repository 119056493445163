import bathroom1 from '../../images/portfolio/bathrooms/1.png'
import bathroom2 from '../../images/portfolio/bathrooms/2.png'
import bathroom3 from '../../images/portfolio/bathrooms/3.png'
import bathroom4 from '../../images/portfolio/bathrooms/4.png'
import bathroom5 from '../../images/portfolio/bathrooms/5.png'
import bathroom6 from '../../images/portfolio/bathrooms/6.png'

import bedrooms1 from '../../images/portfolio/bedrooms/1.png'
import bedrooms2 from '../../images/portfolio/bedrooms/2.png'
import bedrooms3 from '../../images/portfolio/bedrooms/3.png'
import bedrooms4 from '../../images/portfolio/bedrooms/4.png'
import bedrooms5 from '../../images/portfolio/bedrooms/5.png'
import bedrooms6 from '../../images/portfolio/bedrooms/6.png'
import bedrooms7 from '../../images/portfolio/bedrooms/7.png'
import bedrooms8 from '../../images/portfolio/bedrooms/8.png'
import bedrooms9 from '../../images/portfolio/bedrooms/9.png'
import bedrooms10 from '../../images/portfolio/bedrooms/10.png'
import bedrooms11 from '../../images/portfolio/bedrooms/11.png'
import bedrooms12 from '../../images/portfolio/bedrooms/12.png'

import commercial1 from '../../images/portfolio/commercial/1.png'
import commercial2 from '../../images/portfolio/commercial/2.png'
import commercial3 from '../../images/portfolio/commercial/3.png'
import commercial4 from '../../images/portfolio/commercial/4.png'
import commercial5 from '../../images/portfolio/commercial/5.png'
import commercial6 from '../../images/portfolio/commercial/6.png'
import commercial7 from '../../images/portfolio/commercial/7.png'
import commercial8 from '../../images/portfolio/commercial/8.png'
import commercial9 from '../../images/portfolio/commercial/9.png'
import commercial10 from '../../images/portfolio/commercial/10.png'
import commercial11 from '../../images/portfolio/commercial/11.png'
import commercial12 from '../../images/portfolio/commercial/12.png'

import designer1 from '../../images/portfolio/designer-items/1.png'
import designer2 from '../../images/portfolio/designer-items/2.png'
import designer3 from '../../images/portfolio/designer-items/3.png'
import designer4 from '../../images/portfolio/designer-items/4.png'
import designer5 from '../../images/portfolio/designer-items/5.png'
import designer6 from '../../images/portfolio/designer-items/6.png'
import designer7 from '../../images/portfolio/designer-items/7.png'
import designer8 from '../../images/portfolio/designer-items/8.png'
import designer9 from '../../images/portfolio/designer-items/9.png'
import designer10 from '../../images/portfolio/designer-items/10.png'
import designer11 from '../../images/portfolio/designer-items/11.png'
import designer12 from '../../images/portfolio/designer-items/12.png'
import designer13 from '../../images/portfolio/designer-items/13.png'

import fabrication1 from '../../images/portfolio/fabrications/1.png'
import fabrication2 from '../../images/portfolio/fabrications/2.png'
import fabrication3 from '../../images/portfolio/fabrications/3.png'
import fabrication4 from '../../images/portfolio/fabrications/4.png'
import fabrication5 from '../../images/portfolio/fabrications/5.png'
import fabrication6 from '../../images/portfolio/fabrications/6.png'
import fabrication7 from '../../images/portfolio/fabrications/7.png'
import fabrication8 from '../../images/portfolio/fabrications/8.png'
import fabrication9 from '../../images/portfolio/fabrications/9.png'
import fabrication10 from '../../images/portfolio/fabrications/10.png'
import fabrication11 from '../../images/portfolio/fabrications/11.png'
import fabrication12 from '../../images/portfolio/fabrications/12.png'
import fabrication13 from '../../images/portfolio/fabrications/13.png'
import fabrication14 from '../../images/portfolio/fabrications/14.png'
import fabrication15 from '../../images/portfolio/fabrications/15.png'

import kitchen1 from '../../images/portfolio/kitchens/1.png'
import kitchen2 from '../../images/portfolio/kitchens/2.png'
import kitchen3 from '../../images/portfolio/kitchens/3.png'
import kitchen4 from '../../images/portfolio/kitchens/4.png'
import kitchen5 from '../../images/portfolio/kitchens/5.png'
import kitchen6 from '../../images/portfolio/kitchens/6.png'
import kitchen7 from '../../images/portfolio/kitchens/7.png'
import kitchen8 from '../../images/portfolio/kitchens/8.png'
import kitchen9 from '../../images/portfolio/kitchens/9.png'
import kitchen10 from '../../images/portfolio/kitchens/10.png'
import kitchen11 from '../../images/portfolio/kitchens/11.png'
import kitchen12 from '../../images/portfolio/kitchens/12.png'

import living1 from '../../images/portfolio/living-rooms/1.png'
import living2 from '../../images/portfolio/living-rooms/2.png'
import living3 from '../../images/portfolio/living-rooms/3.jpg' // jpg format.
import living4 from '../../images/portfolio/living-rooms/4.png'
import living5 from '../../images/portfolio/living-rooms/5.png'
import living6 from '../../images/portfolio/living-rooms/6.jpg' // jpg format.
import living7 from '../../images/portfolio/living-rooms/7.png'
import living8 from '../../images/portfolio/living-rooms/8.png'
import living9 from '../../images/portfolio/living-rooms/9.jpg' // jpg format.
import living10 from '../../images/portfolio/living-rooms/10.png'
import living11 from '../../images/portfolio/living-rooms/11.png'
import living12 from '../../images/portfolio/living-rooms/12.jpg' // jpg format.
import living13 from '../../images/portfolio/living-rooms/13.png'
import living14 from '../../images/portfolio/living-rooms/14.png'
import living15 from '../../images/portfolio/living-rooms/15.jpg' // jpg format.
import living16 from '../../images/portfolio/living-rooms/16.jpg' // jpg format.
import living17 from '../../images/portfolio/living-rooms/17.jpg' // jpg format.
import living18 from '../../images/portfolio/living-rooms/18.jpg' // jpg format.
import living19 from '../../images/portfolio/living-rooms/19.png'
import living20 from '../../images/portfolio/living-rooms/20.png'

import window1 from '../../images/portfolio/windows/1.png'
import window2 from '../../images/portfolio/windows/2.png'
import window3 from '../../images/portfolio/windows/3.png'
import window4 from '../../images/portfolio/windows/4.png'
import window5 from '../../images/portfolio/windows/5.png'
import window6 from '../../images/portfolio/windows/6.png'
import window7 from '../../images/portfolio/windows/7.png'

const bathrooms = [
  {
    id: 1,
    src: bathroom1,
    alt: 'Bathroom',
  },
  {
    id: 2,
    src: bathroom2,
    alt: 'Bathroom',
  },
  {
    id: 3,
    src: bathroom3,
    alt: 'Bathroom',
  },
  {
    id: 4,
    src: bathroom4,
    alt: 'Bathroom',
  },
  {
    id: 5,
    src: bathroom5,
    alt: 'Bathroom',
  },
  {
    id: 6,
    src: bathroom6,
    alt: 'Bathroom',
  },
]

const bedrooms = [
  {
    id: 1,
    src: bedrooms1,
    alt: 'Bedroom',
  },
  {
    id: 2,
    src: bedrooms2,
    alt: 'Bedroom',
  },
  {
    id: 3,
    src: bedrooms3,
    alt: 'Bedroom',
  },
  {
    id: 4,
    src: bedrooms4,
    alt: 'Bedroom',
  },
  {
    id: 5,
    src: bedrooms5,
    alt: 'Bedroom',
  },
  {
    id: 6,
    src: bedrooms6,
    alt: 'Bedroom',
  },
  {
    id: 7,
    src: bedrooms7,
    alt: 'Bedroom',
  },
  {
    id: 8,
    src: bedrooms8,
    alt: 'Bedroom',
  },
  {
    id: 9,
    src: bedrooms9,
    alt: 'Bedroom',
  },
  {
    id: 10,
    src: bedrooms10,
    alt: 'Bedroom',
  },
  {
    id: 11,
    src: bedrooms11,
    alt: 'Bedroom',
  },
  {
    id: 12,
    src: bedrooms12,
    alt: 'Bedroom',
  },
]

const commercial = [
  {
    id: 1,
    src: commercial1,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 2,
    src: commercial2,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 3,
    src: commercial3,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 4,
    src: commercial4,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 5,
    src: commercial5,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 6,
    src: commercial6,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 7,
    src: commercial7,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 8,
    src: commercial8,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 9,
    src: commercial9,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 10,
    src: commercial10,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 11,
    src: commercial11,
    alt: 'Commercial spaces / Offices',
  },
  {
    id: 12,
    src: commercial12,
    alt: 'Commercial spaces / Offices',
  },
]

const designer = [
  {
    id: 1,
    src: designer1,
    alt: 'Designer',
  },
  {
    id: 2,
    src: designer2,
    alt: 'Designer',
  },
  {
    id: 3,
    src: designer3,
    alt: 'Designer',
  },
  {
    id: 4,
    src: designer4,
    alt: 'Designer',
  },
  {
    id: 5,
    src: designer5,
    alt: 'Designer',
  },
  {
    id: 6,
    src: designer6,
    alt: 'Designer',
  },
  {
    id: 7,
    src: designer7,
    alt: 'Designer',
  },
  {
    id: 8,
    src: designer8,
    alt: 'Designer',
  },
  {
    id: 9,
    src: designer9,
    alt: 'Designer',
  },
  {
    id: 10,
    src: designer10,
    alt: 'Designer',
  },
  {
    id: 11,
    src: designer11,
    alt: 'Designer',
  },
  {
    id: 12,
    src: designer12,
    alt: 'Designer',
  },
  {
    id: 13,
    src: designer13,
    alt: 'Designer',
  },
]

const fabrications = [
  {
    id: 1,
    src: fabrication1,
    alt:
      'S.S. Fabrication Works – S.S. Railing work of all type and design including Glass Work',
  },
  {
    id: 2,
    src: fabrication2,
    alt:
      'FabricS.S. Fabrication Works – S.S. Railing work of all type and design including Glass WorkaS.S. Fabrication Works – S.S. Railing work of all type and design including Glass Workion',
  },
  {
    id: 3,
    src: fabrication3,
    alt:
      'S.S. Fabrication Works – S.S. Railing work of all type and design including Glass Work',
  },
  {
    id: 4,
    src: fabrication4,
    alt:
      'S.S. Fabrication Works – S.S. Railing work of all type and design including Glass Work',
  },
  {
    id: 5,
    src: fabrication5,
    alt:
      'S.S. Fabrication Works – S.S. Railing work of all type and design including Glass Work',
  },
  {
    id: 6,
    src: fabrication6,
    alt:
      'S.S. Fabrication Works – S.S. Railing work of all type and design including Glass Work',
  },
  {
    id: 7,
    src: fabrication7,
    alt:
      'S.S. Fabrication Works – S.S. Railing work of all type and design including Glass Work',
  },
  {
    id: 8,
    src: fabrication8,
    alt: 'M S Fabrication Work',
  },
  {
    id: 9,
    src: fabrication9,
    alt: 'M S Fabrication Work',
  },
  {
    id: 10,
    src: fabrication10,
    alt: 'M S Fabrication Work',
  },
  {
    id: 11,
    src: fabrication11,
    alt: 'M S Fabrication Work',
  },
  {
    id: 12,
    src: fabrication12,
    alt: 'M S Fabrication Work',
  },
  {
    id: 13,
    src: fabrication13,
    alt: 'M S Fabrication Work',
  },
  {
    id: 14,
    src: fabrication14,
    alt: 'M S Designer Gate',
  },
  {
    id: 15,
    src: fabrication15,
    alt: 'M S Designer Gate',
  },
]

const kitchens = [
  {
    id: 1,
    src: kitchen1,
    alt: 'Kitchen',
  },
  {
    id: 2,
    src: kitchen2,
    alt: 'Kitchen',
  },
  {
    id: 3,
    src: kitchen3,
    alt: 'Kitchen',
  },
  {
    id: 4,
    src: kitchen4,
    alt: 'Kitchen',
  },
  {
    id: 5,
    src: kitchen5,
    alt: 'Kitchen',
  },
  {
    id: 6,
    src: kitchen6,
    alt: 'Kitchen',
  },
  {
    id: 7,
    src: kitchen7,
    alt: 'Kitchen',
  },
  {
    id: 8,
    src: kitchen8,
    alt: 'Kitchen',
  },
  {
    id: 9,
    src: kitchen9,
    alt: 'Kitchen',
  },
  {
    id: 10,
    src: kitchen10,
    alt: 'Kitchen',
  },
  {
    id: 11,
    src: kitchen11,
    alt: 'Kitchen',
  },
  {
    id: 12,
    src: kitchen12,
    alt: 'Kitchen',
  },
]

const living = [
  {
    id: 1,
    src: living1,
    alt: 'Living Room & Hall',
  },
  {
    id: 2,
    src: living2,
    alt: 'Living Room & Hall',
  },
  {
    id: 3,
    src: living3,
    alt: 'Arab Themed Living Room Design',
  },
  {
    id: 4,
    src: living4,
    alt: 'Living Room & Hall',
  },
  {
    id: 5,
    src: living5,
    alt: 'Living Room & Hall',
  },
  {
    id: 6,
    src: living6,
    alt: 'Arab Themed Living Room Design',
  },
  {
    id: 7,
    src: living7,
    alt: 'Living Room & Hall',
  },
  {
    id: 8,
    src: living8,
    alt: 'Living Room & Hall',
  },
  {
    id: 9,
    src: living9,
    alt: 'Arab Themed Living Room Design',
  },
  {
    id: 10,
    src: living10,
    alt: 'Living Room & Hall',
  },
  {
    id: 11,
    src: living11,
    alt: 'Living Room & Hall',
  },
  {
    id: 12,
    src: living12,
    alt: 'Arab Themed Living Room Design',
  },
  {
    id: 13,
    src: living13,
    alt: 'Living Room & Hall',
  },
  {
    id: 14,
    src: living14,
    alt: 'Living Room & Hall',
  },
  {
    id: 15,
    src: living15,
    alt: 'Arab Themed Living Room Design',
  },
  {
    id: 16,
    src: living16,
    alt: 'Living Room & Hall',
  },
  {
    id: 17,
    src: living17,
    alt: 'Living Room & Hall',
  },
  {
    id: 18,
    src: living18,
    alt: 'Arab Themed Living Room Design',
  },
  {
    id: 19,
    src: living19,
    alt: 'Living Room & Hall',
  },
  {
    id: 20,
    src: living20,
    alt: 'Living Room & Hall',
  },
]

const windows = [
  {
    id: 1,
    src: window1,
    alt: 'Aluminium Section Windows',
  },
  {
    id: 2,
    src: window2,
    alt: 'Aluminium Section Windows',
  },
  {
    id: 3,
    src: window3,
    alt: 'Aluminium Section Windows',
  },
  {
    id: 4,
    src: window4,
    alt: 'Aluminium Section Windows',
  },
  {
    id: 5,
    src: window5,
    alt: 'Aluminium Section Windows',
  },
  {
    id: 6,
    src: window6,
    alt: 'Aluminium Section Windows',
  },
  {
    id: 7,
    src: window7,
    alt: 'Aluminium Section Windows',
  },
]

export const portfolio = [
  living,
  bedrooms,
  bathrooms,
  kitchens,
  commercial,
  designer,
  fabrications,
  windows,
]
