import React from 'react'
import SocialIcons from '../social-icons'
import styles from './index.module.scss'

export default ({ heading }) => (
  <aside className={styles.aside}>
    <h1 className={styles.aside__heading}>
      <span>{heading}</span>
    </h1>
    <SocialIcons />
    <span className={styles.aside__share}>SHARE</span>
  </aside>
)
