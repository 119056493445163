import React from 'react'
import { Link } from 'react-router-dom'
import SocialIcons from '../../social-icons'
import styles from './index.module.scss'

export default () => (
  <div className={styles.sideNav}>
    <nav className={styles.sideNav__links}>
      <Link to="/">HOME</Link>
      <Link to="/about-us">ABOUT</Link>
      <Link to="/portfolio">PORTFOLIO</Link>
      <Link to="/services">SERVICES</Link>
      <Link to="/contact">CONTACTS</Link>
    </nav>
    <div className={styles.sideNav__social}>
      <span>FOLLOW US:</span>
      <SocialIcons horizontal={true} />
    </div>
  </div>
)
