import React, { useState, useEffect } from 'react'
import { Modal } from '../../modal'
import { scrollToSection } from '../../../utils'
import styles from './index.module.scss'

export default ({ project }) => (
  <div className={styles.content}>
    <Banner
      heading={project.heading}
      description={project.description}
      image={project.images && project.images[0]}
    />
    <Detials
      details={project.details}
      info={project.paragraphs}
      images={project.images}
      videos={project.videos}
    />
  </div>
)

const Banner = ({ heading, description, image }) => {
  const doc = new DOMParser().parseFromString(heading, 'text/html') // for retrieving text with html DOM element.
  const startingText = doc.body.firstChild
  const highlightedTextElement = startingText.nextSibling // span tag.
  const endingText =
    highlightedTextElement && highlightedTextElement.nextSibling
  return (
    <section className={styles.banner}>
      <div>
        <div className={styles.banner__txtContainer}>
          <h2>
            {startingText.textContent}
            {highlightedTextElement && highlightedTextElement.textContent}
            {endingText && endingText.textContent}
          </h2>
          <div>
            <p>{description}</p>
            <button onClick={() => scrollToSection('project-details')}>
              DETAILS
            </button>
          </div>
        </div>
      </div>
      <img src={image && image.src} alt={image && image.alt} />
    </section>
  )
}

const Detials = ({ details, info, images, videos }) => (
  <section id="project-details" className={styles.txtContainer}>
    <div>
      <ProjectDetails content={details} />
      <div className={styles.txtContainer__content}>
        <ProjectInfo content={info} />
        <Gallery videos={videos} images={images} />
      </div>
    </div>
  </section>
)

const ProjectDetails = ({ content }) => (
  <div className={styles.details}>
    <h3>Project Details</h3>
    <p>{content && content.shortSummary}</p>
    <ul>
      <li>DATE: {content && content.date}</li>
      <li>CLIENT: {content && content.client}</li>
      <li>STATUS: {content && content.status}</li>
      <li>
        LOCATION: <span>{content && content.location}</span>
      </li>
    </ul>
  </div>
)

const ProjectInfo = ({ content }) => (
  <div className={styles.info}>
    <h3>Project Info</h3>
    {content &&
      content.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
    <div></div>
  </div>
)

const Gallery = ({ images, videos }) => {
  const [mobileView, setView] = useState(true)
  const [modalIsOpen, openModal] = useState(false)
  const [image, setImage] = useState({})

  useEffect(() => {
    if (window.innerWidth >= 768) {
      setView(false)
    } else {
      setView(true)
    }
  }, [mobileView])

  const closeModal = () => openModal(false)

  const selectedImage = image => {
    setImage(image)
    openModal(true)
  }

  return (
    <div className={styles.gallery}>
      <h3>Gallery</h3>
      <div className={styles.gallery__grid}>
        {videos &&
          videos.map((video, index) => (
            <div
              key={index}
              className={`${styles.gallery__gridItem} ${styles.gallery__gridItem_video}`}
            >
              <video
                src={video}
                type="video/mp4"
                preload="metadata"
                controls
                controlsList="nodownload"
                muted
                width={mobileView ? 150 : 250}
              >
                Sorry, your browser doesn't support embedded videos.
              </video>
            </div>
          ))}
        <br />
        {images &&
          images.map((image, index) => (
            <div
              key={index}
              className={`${styles.gallery__gridItem} ${styles.gallery__gridItem_image}`}
              onClick={() => selectedImage(image)}
            >
              <img src={image.src} alt={image.alt} />
            </div>
          ))}
      </div>
      <Modal
        modalIsOpen={modalIsOpen}
        closeModalHandler={closeModal}
        contentLabel="Fullscreen Image"
        styling={styles.modal}
      >
        <img src={image.src} alt={image.alt} className={styles.modal__image} />
        <div className={styles.modal__txt}>
          <span>{image.alt}</span>
        </div>
      </Modal>
    </div>
  )
}
