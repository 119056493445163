import React from 'react'
import Section from '../../section'
import SerialNoLarge from '../../serial-no-large'
import ImageAndList from '../../image-and-list'
import OrderProject from '../order-project'
import { services } from '../../../utils'
import styles from './index.module.scss'

export default () => (
  <div className={styles.content}>
    <Section>
      {services.map((service, index) => (
        <Service
          key={index}
          srNo={index + 1}
          name={service.name}
          shortSummary={service.shortSummary}
          image={service.image}
          list={service.list}
          description={service.description}
          // price={service.price}
        />
      ))}
      <OrderProject />
    </Section>
  </div>
)

const Service = ({
  srNo,
  name,
  shortSummary,
  image,
  list,
  description,
  // price,
}) => (
  <div className={styles.service}>
    <SerialNoLarge srNo={srNo} />
    <h3>{name}</h3>
    <p>{shortSummary}</p>
    <ImageAndList name={name} image={image} list={list} />
    <div className={styles.service__description}>
      <p>{description}</p>
      {/* <div className={styles.service__price}>
        <div>
          <span>Price </span>
          {price}
        </div>
      </div> */}
    </div>
  </div>
)
