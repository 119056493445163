import React from 'react'
import Section from '../../section'
import SerialNoLarge from '../../serial-no-large'
import { story, stats, team } from '../../../utils'
import styles from './index.module.scss'

export default () => (
  <div className={styles.content}>
    <Section>
      <Story />
      <Stats />
      <Team />
    </Section>
  </div>
)

const Story = () => (
  <div className={styles.story}>
    <SerialNoLarge srNo={1} />
    <h3>{story.name}</h3>
    <p>{story.shortSummary}</p>
    <img src={story.image} alt={story.name} />
    <div className={styles.story__txt}>
      <h4>{story.highlight}</h4>
      <div>
        {story.paragraphs.map((paragraph, index) => {
          const doc = new DOMParser().parseFromString(paragraph, 'text/html') // for retrieving text with html DOM element.
          const firstElement = doc.body.firstChild
          const strongTextElement =
            firstElement.nextSibling !== null
              ? firstElement.nextSibling
              : undefined // strong tag.
          const lastElement =
            strongTextElement && strongTextElement.nextSibling !== null
              ? strongTextElement.nextSibling
              : undefined
          return (
            <p key={index}>
              {firstElement.textContent}
              {strongTextElement && (
                <strong>{strongTextElement.textContent}</strong>
              )}
              {lastElement && lastElement.textContent}
            </p>
          )
        })}
      </div>
    </div>
  </div>
)

const Stats = () => (
  <div className={styles.stats}>
    <div>
      <span>{stats.projects}</span>
      <h5>FINISHED PROJECTS</h5>
    </div>
    <div>
      <span>{stats.customers}</span>
      <h5>HAPPY CUSTOMERS</h5>
    </div>
  </div>
)

const Team = () => (
  <div className={styles.team}>
    <SerialNoLarge srNo={2} />
    <h3>{team.name}</h3>
    <p>{team.shortSummary}</p>
    <ul>
      {team.staff.map((role, index) => (
        <li key={index}>{role}</li>
      ))}
    </ul>
  </div>
)
