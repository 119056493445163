import React from 'react'
import { Link } from 'react-router-dom'
import { projects } from '../../../../utils'
import styles from './index.module.scss'

export default ({ positions, direction, translateValues, determineStyle }) => (
  <div className={styles.txtContainer}>
    {projects.map(project => {
      const showAnimation =
        (positions[project.id] > translateValues[0] && direction === 'prev') ||
        (positions[project.id] < translateValues.slice(-1).pop() &&
          direction === 'next') // "translateValues[0]" and "translateValues.slice(-1).pop()" gives 1st and last values repectively in the translate array.
      const txtStyle = determineStyle(project.id, showAnimation, 'X')

      const doc = new DOMParser().parseFromString(project.heading, 'text/html') // for retrieving text with html DOM element.
      const startingText = doc.body.firstChild
      const highlightedTextElement = startingText.nextSibling // span tag.
      const endingText = highlightedTextElement.nextSibling
      return (
        <div key={project.id} style={txtStyle}>
          <div className={styles.txtContainer__grid}>
            <div className={styles.txtContainer__gridItem1}>
              <span>{project.date}</span>
            </div>
            <h2 className={styles.txtContainer__gridItem2}>
              {startingText.textContent}
              <br />
              <span>{highlightedTextElement.textContent}</span>
              {endingText.textContent}
            </h2>
            <p className={styles.txtContainer__gridItem3}>
              {project.description}
            </p>
            <Link
              to={{ pathname: '/project-details', state: project.id }}
              className={styles.txtContainer__gridItem4}
            >
              <span>VIEW PROJECT</span>
            </Link>
          </div>
        </div>
      )
    })}
  </div>
)
