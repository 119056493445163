import React, { useState } from 'react'
import styles from './index.module.scss'

export default () => {
  const [sideDrawerOpen, toggleSideDrawer] = useState(false)

  const clickHandler = () => toggleSideDrawer(!sideDrawerOpen)

  return (
    <div
      className={
        sideDrawerOpen
          ? `${styles.sideDrawer} ${styles.sideDrawer_open}`
          : styles.sideDrawer
      }
    >
      <div className={styles.details}>
        <span className={styles.details__vertical} onClick={clickHandler}>
          {sideDrawerOpen ? <>&#8594;</> : <>&#8592;</>}
        </span>
        <div className={styles.details__txt} onClick={clickHandler}>
          <ul>
            Works starts for:
            <li>1BHK - 3.99L</li>
            <li>2BHK - 4.99L</li>
            <li>3BHK - 5.99L</li>
          </ul>
          <span>
            <strong>* Conditions apply</strong>
          </span>
        </div>
      </div>
    </div>
  )
}
