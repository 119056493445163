import React from 'react'
import GetInTouch from '../../get-in-touch-link'
import styles from './index.module.scss'

export default () => (
  <div className={styles.orderProject}>
    <div>
      <h4>READY TO ORDER YOUR PROJECT ?</h4>
    </div>
    <GetInTouch white={true} />
  </div>
)
