import { contacts } from './social'
import bannerBg from '../../images/backgrounds/bg11.jpg'
import contactBg from '../../images/backgrounds/bg7.jpg'

export const banner = {
  image: bannerBg,
  heading: 'CONTACT US',
  subTitle:
    'THIS IS THE PLACE WHERE YOU CAN REACH US',
}

export const details = {
  name: 'CONTACT DETAILS',
  shortSummary:
    'TELL US ABOUT YOUR DREAM HOME, AND WE WILL MAKE IT REALITY FOR YOU',
  image: contactBg,
  list: [
    `<strong>01. </strong>WRITE: <a href="mailto:${contacts.email}">${contacts.email}</a>`, // strong tag is compulsory along with anchor or span tag.
    `<strong>02. </strong>CALL: <a href="tel:${contacts.phone}">${contacts.phone}</a>`, // strong tag is compulsory along with anchor or span tag.
    `<strong>03. </strong>VISIT: <span>${contacts.address}</span>`, // strong tag is compulsory along with anchor or span tag.
  ],
}

export const getInTouch = {
  name: 'GET IN TOUCH',
  shortSummary:
    'TELL US ABOUT YOUR DREAM HOME, AND WE WILL MAKE IT REALITY FOR YOU',
}
