import React from 'react'
import GetInTouch from '../get-in-touch-link'
// import logo from '../../images/logo.svg'
import Logo from '../logo'
import heartIcon from '../../images/heart-icon.svg'
import { scrollToSection, contacts } from '../../utils'
import styles from './index.module.scss'

export default ({ full }) => (
  <footer
    className={full ? `${styles.footer} ${styles.footer_full}` : styles.footer}
  >
    <div>
      <div className={styles.footer__details}>
        {/* <img src={logo} alt="Home Interior Studio" /> */}
        <Logo />
        <p>
          Our team takes over everything, from an idea and concept development
          to realization. We believe in traditions and incorporate them within
          our innovations. Client is the soul of the project.
        </p>
      </div>
      <div className={styles.footer__contact}>
        <span>CONTACTS</span>
        <ul className={styles.footer__list}>
          <li>
            <span>CALL</span>
            <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
          </li>
          <li>
            <span>WRITE</span>
            <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
          </li>
          <li>
            <span>FIND US</span>
            <span>Mumbai, MAHARASHTRA</span>
          </li>
        </ul>
        <div>
          <GetInTouch />
        </div>
      </div>
    </div>
    <div
      className={
        full
          ? `${styles.footer__copywrite} ${styles.footer__copywrite_full}`
          : styles.footer__copywrite
      }
    >
      <div>
        <span>&copy; HOME INTERIOR STUDIO 2019 / ALL RIGHTS RESERVED. </span>
        <div className={styles.footer__love}>
          Made with&nbsp;
          <img src={heartIcon} alt="Made with Love at PROCODES" />{' '}
          &nbsp;by&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://procodes.io/"
          >
            PROCODES
          </a>
        </div>
        <div className={styles.footer__credits}>
          Icons made by{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.flaticon.com/authors/freepik"
          >
            Freepik
          </a>
          ,{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.flaticon.com/authors/those-icons"
          >
            Those Icons
          </a>{' '}
          and{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.flaticon.com/authors/dave-gandy"
          >
            Dave Gandy
          </a>{' '}
          from{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.flaticon.com/home"
          >
            www.flaticon.com
          </a>
        </div>
      </div>
      <button onClick={() => scrollToSection('header')}>
        BACK TO TOP &nbsp;&nbsp;&#8593;
      </button>
    </div>
  </footer>
)
