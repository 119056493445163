import skyline1 from '../../images/skyline/skyline1.jpg'
import skyline2 from '../../images/skyline/skyline2.jpg'
import skyline3 from '../../images/skyline/skyline3.jpg'
import skyline4 from '../../images/skyline/skyline4.jpg'
import skyline5 from '../../images/skyline/skyline5.jpg'
import skyline6 from '../../images/skyline/skyline6.jpg'
import skyline7 from '../../images/skyline/skyline7.jpg'
import skyline8 from '../../images/skyline/skyline8.jpg'
import skyline9 from '../../images/skyline/skyline9.jpg'
import skyline10 from '../../images/skyline/skyline10.jpg'
import skyline11 from '../../images/skyline/skyline11.jpg'
import skyline12 from '../../images/skyline/skyline12.jpg'
import skyline13 from '../../images/skyline/skyline13.jpg'
import skyline14 from '../../images/skyline/skyline14.jpg'
import skyline15 from '../../images/skyline/skyline15.jpg'
import skyline16 from '../../images/skyline/skyline16.jpg'
import skyline17 from '../../images/skyline/skyline17.jpg'
import skyline18 from '../../images/skyline/skyline18.jpg'
import skyline19 from '../../images/skyline/skyline19.jpg'
import skyline20 from '../../images/skyline/skyline20.jpg'
import skyline21 from '../../images/skyline/skyline21.jpg'
import skyline22 from '../../images/skyline/skyline22.jpg'
import skyline23 from '../../images/skyline/skyline23.jpg'
import skyline24 from '../../images/skyline/skyline24.jpg'
import skyline25 from '../../images/skyline/skyline25.jpg'
import skyline26 from '../../images/skyline/skyline26.jpg'
import Skyline from '../../images/skyline/skyline.mp4'
import Skyline1 from '../../images/skyline/skyline1.mp4'
import Skyline2 from '../../images/skyline/skyline2.mp4'
import Skyline3 from '../../images/skyline/skyline3.mp4'
import andheri1 from '../../images/andheri/andheri1.jpg'
import andheri2 from '../../images/andheri/andheri2.jpg'
import andheri3 from '../../images/andheri/andheri3.jpg'
import andheri4 from '../../images/andheri/andheri4.jpg'
import andheri5 from '../../images/andheri/andheri5.jpg'
import andheri6 from '../../images/andheri/andheri6.jpg'
import BKC3 from '../../images/andheri/andheri-commercial.jpg'
import Andheri from '../../images/andheri/andheri.mp4'
import Andheri1 from '../../images/andheri/andheri1.mp4'
import Andheri2 from '../../images/andheri/andheri2.mp4'
import Andheri3 from '../../images/andheri/andheri3.mp4'
import bkc from '../../images/bkc/bkc.png'
import bkc1 from '../../images/bkc/bkc1.png'
import bkc2 from '../../images/bkc/bkc2.png'
import bkc3 from '../../images/bkc/bkc3.png'
import bkc4 from '../../images/bkc/bkc4.png'
import bkc5 from '../../images/bkc/bkc5.png'
import BKC from '../../images/bkc/bkc.mp4'
import BKC1 from '../../images/bkc/bkc1.mp4'
import BKC2 from '../../images/bkc/bkc2.mp4'
import Kharghar from '../../images/kharghar/1.jpg'
import Kharghar1 from '../../images/kharghar/2.jpg'
import Kharghar2 from '../../images/kharghar/3.jpg'
import Kharghar3 from '../../images/kharghar/4.jpg'
import Kharghar4 from '../../images/kharghar/5.jpg'
import Kharghar5 from '../../images/kharghar/6.jpg'
import Kharghar6 from '../../images/kharghar/7.jpg'
import Kharghar7 from '../../images/kharghar/8.jpg'
import Kharghar8 from '../../images/kharghar/9.jpg'
import Kharghar9 from '../../images/kharghar/10.jpg'
import Kharghar10 from '../../images/kharghar/11.jpg'
import Kharghar11 from '../../images/kharghar/12.jpg'
import Kharghar12 from '../../images/kharghar/13.jpg'
import Kharghar13 from '../../images/kharghar/14.jpg'
import Kharghar14 from '../../images/kharghar/15.jpg'
import Kharghar15 from '../../images/kharghar/16.jpg'
import Kharghar16 from '../../images/kharghar/17.jpg'
import Kharghar17 from '../../images/kharghar/18.jpg'
import Kharghar18 from '../../images/kharghar/19.jpg'
import Kharghar19 from '../../images/kharghar/20.jpg'
import Kharghar20 from '../../images/kharghar/21.jpg'
import Kharghar21 from '../../images/kharghar/22.jpg'
import Kharghar22 from '../../images/kharghar/23.jpg'
import Kharghar23 from '../../images/kharghar/24.jpg'
import Kharghar24 from '../../images/kharghar/25.jpg'
import Kharghar25 from '../../images/kharghar/26.jpg'
import Kharghar26 from '../../images/kharghar/27.jpg'
import Kharghar27 from '../../images/kharghar/28.jpg'
import Kharghar28 from '../../images/kharghar/29.jpg'
import Kharghar29 from '../../images/kharghar/30.jpg'
import Kharghar30 from '../../images/kharghar/31.jpg'
import Kharghar31 from '../../images/kharghar/32.jpg'
import Kharghar32 from '../../images/kharghar/33.jpg'
import Kharghar33 from '../../images/kharghar/34.jpg'
import Kharghar34 from '../../images/kharghar/35.jpg'
import Kharghar35 from '../../images/kharghar/36.jpg'
import Kharghar36 from '../../images/kharghar/37.jpg'
import Kharghar37 from '../../images/kharghar/38.jpg'
import Kharghar38 from '../../images/kharghar/39.jpg'
import Kharghar39 from '../../images/kharghar/40.jpg'
import Kharghar40 from '../../images/kharghar/41.jpg'
import Kharghar41 from '../../images/kharghar/42.jpg'
import Kharghar42 from '../../images/kharghar/43.jpg'
import Kharghar43 from '../../images/kharghar/44.jpg'
import Kharghar44 from '../../images/kharghar/45.jpg'
import Kharghar45 from '../../images/kharghar/46.jpg'
import Kharghar46 from '../../images/kharghar/47.jpg'
import KhargarMov from '../../images/kharghar/720p.MOV'
import KhargarMov1 from '../../images/kharghar/720p1.MOV'
import KhargarMov2 from '../../images/kharghar/720p2.MOV'

export const projects = [
  {
    id: 1,
    name: 'Kharghar - 2BHK',
    heading: 'Modern <span>Interior</span> Home Design 2020-Lockdown Phase', // span tag is compulsory & it's content will be displayed in secondary-color.
    description:
      "Kharghar - 2BHK. Modern Interior Home Design, project completed during Lockdown phase.  Work completed in 42 days.",
    date: '15 JUNE 2020',
    link: 'https://www.google.com/',
    images: [
      { src: Kharghar, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar1, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar2, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar3, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar4, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar5, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar6, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar7, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar8, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar9, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar10, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar11, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar12, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar13, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar14, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar15, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar16, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar17, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar18, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar19, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar20, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar21, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar22, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar23, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar24, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar25, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar26, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar27, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar28, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar29, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar30, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar31, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar32, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar33, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar34, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar35, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar36, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar37, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar38, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar39, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar40, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar41, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar42, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar43, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar44, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar45, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
      { src: Kharghar46, alt: 'Modern Interior Home Design - Lockdown Phase 2020, Khargar' },
    ], // 1st item in the array is banner image.
    videos: [KhargarMov, KhargarMov1, KhargarMov2],
    details: {
      shortSummary:
        'Modern Interior Home Design - Lockdown Phase 2020',
      date: '15.06.2020',
      client: 'INDIoVIDUAL',
      status: 'COMPLETED',
      location: 'Kharghar',
    },
    paragraphs: [
      'Kharghar - 2BHK work completed within 42 days during lockdown phase with all kind designer furniture work including dining table and chairs, TV unit, center table with hand designing, wall panels, safety door, shoe rack, kitchen sliders, wall highlights, modular kitchen with glass work, children bedroom with full wall cabinet and study table, full storage bed, open space panel and master bedroom with artistic bed panel, full size wardrobe with dressing table along with seating arrangement and bangle box, full storage 7.5/6.5ft bed and balcony grills, balcony passage carpet, rolling chair with Tea table, all kind of electrical\'s including fans, lights wherever required, strip lights, panel lights, hanging light, fish aquarium and kaleen. Mattress/Curtains/Headboards included. False Ceiling/POP work done in Living Room/Both Bedrooms/passage/Kitchen/Balcony. All painting work and texture done in Royale(Asian Paints). Imported wallpaper used in kids bedroom ceiling.',
    ],
  },
  {
    id: 3,
    name: 'Luxury House',
    heading: 'Skyline <span>Apartment</span> Mira Road', // span tag is compulsory & it's content will be displayed in secondary-color.
    description:
      "Lavish 2BHK flat, turnkey project, all work includes window grills, glass work, furniture, POP, electrical, painting (Royale Asian), Sofa. Work completed in 28 days.",
    date: '15 Oct 2019',
    link: 'https://www.google.com/',
    images: [
      { src: skyline1, alt: 'Image Alt Text' },
      { src: skyline2, alt: 'Image Alt Text' },
      { src: skyline3, alt: 'Image Alt Text' },
      { src: skyline4, alt: 'Image Alt Text' },
      { src: skyline5, alt: 'Image Alt Text' },
      { src: skyline5, alt: 'Image Alt Text' },
      { src: skyline6, alt: 'Image Alt Text' },
      { src: skyline7, alt: 'Image Alt Text' },
      { src: skyline8, alt: 'Image Alt Text' },
      { src: skyline9, alt: 'Image Alt Text' },
      { src: skyline10, alt: 'Image Alt Text' },
      { src: skyline11, alt: 'Image Alt Text' },
      { src: skyline12, alt: 'Image Alt Text' },
      { src: skyline13, alt: 'Image Alt Text' },
      { src: skyline14, alt: 'Image Alt Text' },
      { src: skyline15, alt: 'Image Alt Text' },
      { src: skyline16, alt: 'Image Alt Text' },
      { src: skyline17, alt: 'Image Alt Text' },
      { src: skyline18, alt: 'Image Alt Text' },
      { src: skyline19, alt: 'Image Alt Text' },
      { src: skyline20, alt: 'Image Alt Text' },
      { src: skyline21, alt: 'Image Alt Text' },
      { src: skyline22, alt: 'Image Alt Text' },
      { src: skyline23, alt: 'Image Alt Text' },
      { src: skyline24, alt: 'Image Alt Text' },
      { src: skyline25, alt: 'Image Alt Text' },
      { src: skyline26, alt: 'Image Alt Text' },
    ], // 1st item in the array is banner image.
    videos: [Skyline, Skyline1, Skyline2, Skyline3],
    details: {
      shortSummary:
        'TurnKey project, completed in 28 days',
      date: '15.10.2019',
      client: 'INDIoVIDUAL',
      status: 'COMPLETED',
      location: 'MIRA ROAD',
    },
    paragraphs: [
      'Lavish 2BHK flat, turnkey project, all work includes window grills, glass work, furniture, POP, electrical, painting (Royale Asian), Sofa. Work completed in 28 days.',
    ],
  },
  {
    id: 2,
    name: 'Design and Renovation',
    heading: 'Design and Renovation of <span>Flat</span> in Andheri', // span tag is compulsory & it's content will be displayed in secondary-color.
    description:
      "Lavish 2BHK flat, area of work includes POP, with state of art electricals, space saving furnitures which includes foldable bed, modular kitchen with tandems, window grills, full size slideable wardrobes. Project completed in 35days.",
    date: '25 May 2019',
    link: 'https://www.google.com/',
    images: [
      { src: andheri2, alt: 'Image Alt Text' },
      { src: andheri1, alt: 'Image Alt Text' },
      { src: andheri3, alt: 'Image Alt Text' },
      { src: andheri4, alt: 'Image Alt Text' },
      { src: andheri5, alt: 'Image Alt Text' },
      { src: andheri6, alt: 'Image Alt Text' },
    ], // 1st item in the array is banner image.
    videos: [Andheri, Andheri1, Andheri2, Andheri3],
    details: {
      shortSummary:
        'Lavish 2BHK flat, completed in 35 days',
      date: '25.05.2019',
      client: 'INDIVIDUAL',
      status: 'COMPLETED',
      location: 'Andheri',
    },
    paragraphs: [
      'Lavish 2BHK flat, area of work includes POP, with state of art electricals, space saving furnitures which includes foldable bed, modular kitchen with tandems, window grills, full size slideable wardrobes. Project completed in 35days.',
    ],
  },
  {
    id: 0,
    name: 'Classy and Stylish Interior',
    heading: 'Design of Classy <span>Commercial</span> Interior', // span tag is compulsory & it's content will be displayed in secondary-color.
    description:
      "Commerical site completed within 60 days with all kind of glass work, ACP sheet and board work, exclusive cabins, with artistic boss table and chairs. Work includes all kind of electricals, furnitures, chairs, boards, shutters, etc.",
    date: '20 Dec 2019',
    link: 'https://www.google.com/',
    images: [
      { src: BKC3, alt: 'Design of Classy Commercial Interior'},
      { src: bkc, alt: 'Design of Classy Commercial Interior' },
      { src: bkc1, alt: 'Design of Classy Commercial Interior' },
      { src: bkc2, alt: 'Design of Classy Commercial Interior' },
      { src: bkc3, alt: 'Design of Classy Commercial Interior' },
      { src: bkc4, alt: 'Design of Classy Commercial Interior' },
      { src: bkc5, alt: 'Design of Classy Commercial Interior' },
    ], // 1st item in the array is banner image.
    videos: [BKC, BKC1, BKC2],
    details: {
      shortSummary:
        'Commercial project completed in 60 days.',
      date: '26.05.2019',
      client: 'ATS AIRCON PVT LTD',
      status: 'COMPLETED',
      location: 'BANDRA KURLA COMPLEX',
    },
    paragraphs: [
      'Commerical site completed within 60 days with all kind of glass work, ACP sheet and board work, exclusive cabins, with artistic boss table and chairs. Work includes all kind of electricals, furnitures, chairs, boards, shutters, etc.',
    ],
  },
]
