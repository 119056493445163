import React from 'react'
import './index.scss'

export const Masonry = ({ array, square, viewImage }) => (
  <div className="grid-layout">
    {array.map(imageObj => {
      return imageObj.map(image => (
        <div
          key={image.id}
          className={`grid-item ${
            square && image.id % 3 === 0 ? 'square' : undefined
          }`}
          onClick={() => viewImage(image)}
        >
          <img src={image.src} alt={image.name} />
        </div>
      ))
    })}
  </div>
)
