import React, { useState } from 'react'
import { socialIcons } from '../../../utils'
import styles from './index.module.scss'

export default () => {
  const [links, toggleLink] = useState(false)

  const hideLinks = () => toggleLink(false)

  return (
    <>
      <button className={styles.shareBtn} onClick={() => toggleLink(!links)}>
        <span>SHARE</span>
      </button>
      <SocialLinks isOpen={links} onHide={hideLinks} />
    </>
  )
}

const SocialLinks = ({ isOpen, onHide }) => (
  <div className={isOpen ? `${styles.social} ${styles.active}` : styles.social}>
    <button className={styles.social__closeBtn} onClick={onHide}>
      CLOSE
    </button>
    <div className={styles.social__links}>
      {socialIcons.map((icon, index) => (
        <a
          key={index}
          target="_blank"
          rel="noopener noreferrer"
          href={icon.link}
        >
          {icon.for}
        </a>
      ))}
    </div>
  </div>
)
