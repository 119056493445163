import React, { useState } from 'react'
import styles from './index.module.scss'

export default () => {
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
  }

  const { values, handleChange, handleSubmit } = useForm(
    initialValues,
    submitForm
  )

  function submitForm() {
    // code for sending the details to backend should be written below.
    console.log(values)

    // if response is success then below code is executed.
    alert('Thank you for contacting us')
    // temp delay for testing.
    return new Promise(resolve => {
      setTimeout(resolve, 1000)
    }) // form details are resetted/cleared.
  }

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <input
        type="text"
        placeholder="Your Name*"
        name="name"
        value={values.name}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        placeholder="Email Address*"
        name="email"
        value={values.email}
        onChange={handleChange}
        required
      />
      <input
        type="tel"
        placeholder="Phone*"
        name="phone"
        value={values.phone}
        onChange={handleChange}
        required
      />
      <textarea
        placeholder="Your Message: "
        name="message"
        value={values.message}
        onChange={handleChange}
      />
      <button>SEND MESSAGE</button>
    </form>
  )
}

const useForm = (initialValues, callback) => {
  const [values, setValues] = useState(initialValues)

  const handleChange = e => {
    e.persist()
    setValues(values => ({ ...values, [e.target.name]: e.target.value }))
  }

  const handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }
    callback().then(resetValues) // here 'submitForm' is passed as callback and resetValues is executed if callback (i.e. submitForm) is success.
  }

  const resetValues = () => {
    setValues({ ...initialValues })
  }

  return {
    values,
    handleChange,
    handleSubmit,
  }
}
