import bannerBg from '../../images/backgrounds/bg10.jpg'
import architectureBg from '../../images/skyline/skyline1.jpg'
import interiorBg from '../../images/andheri/andheri2.jpg'

export const banner = {
  image: bannerBg,
  heading: 'OUR <br />SERVICES', // <br /> can be optional.
  subTitle:
    'HASSLE FREE INTERIOR DESIGN SERVICE, THAT YOU WILL LOVE.',
}

export const services = [
  {
    name: 'Architecture',
    shortSummary:
      'WE CREATE DESIGNS THAT INSPIRE, CONNECT AND PERFORM.',
    image: architectureBg,
    list: [
      '<strong>01. </strong>LOCATION: <span>Mira Road</span>', // strong tag is compulsory along with anchor or span tag.
      '<strong>02. </strong>STATUS: <span>COMPLETED</span>', // strong tag is compulsory along with anchor or span tag.
      '<strong>03. </strong>Time: <span>28 Days</span>', // strong tag is compulsory along with anchor or span tag.
    ],
    description:
      'Architecture, the art and technique of designing and building, as distinguished from the skills associated with construction - Britannica. We can help individuals and corporates to design their house or office in a professional manner. We have team of designers who have experience over of 20 years in the industry.',
    price: '$1250-$3350',
  },
  {
    name: 'Interior Design',
    shortSummary:
      'WE ARE EXPERTS IN WHAT WE DO, DON\'T JUST BELIEVE US CHECK OUT PROJECTS THAT WE HAVE COMPLETED.',
    image: interiorBg,
    list: [
      '<strong>01. </strong>Location: <span>Andheri</span>', // strong tag is compulsory along with anchor or span tag.
      '<strong>02. </strong>Status: <span>Completed</span>', // strong tag is compulsory along with anchor or span tag.
      '<strong>03. </strong>TIME: <span>35 days</span>', // strong tag is compulsory along with anchor or span tag.
    ],
    description:
      'We understand that interior of home or office is a reflection of the people living theere. It says lot about you. It is a place where you spent most of your time. Our interior design covers broadly living room, kitchen, bedroom, bathroom, terrace, dining area etc. This includes selecting material finishes, furniture, lighting, plumbing and so on. Each of our projects had a unique challenge and we approached them with zeal, dedication and efficiency.',
    price: '$1250-$3350',
  },
]
